import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Fogafin from './images/fogafin.inline.svg';
import Vigilado from './images/vigilado.inline.svg';

const staticMenuItems = [
  {
    title: 'Recibe pagos',
    link: '',
    childPages: [
      {
        title: 'Pagos presenciales',
        pages: [
          {
            title: 'Ver datáfonos',
            slug: 'datafonos',
            icon: <StaticImage src="./images/dphs.png" alt="Bold menu" />,
          },
          {
            title: 'Datáfono Smart Pro',
            badge: 'Nuevo',
            blank: true,
            link: 'https://datafonos.bold.co/smart-pro/',
            icon: <StaticImage src="./images/smart-pro.png" alt="Bold menu" />,
          },
          {
            title: 'Datáfono Smart',
            slug: 'datafonos/smart',
            icon: <StaticImage src="./images/smart.png" alt="Bold menu" />,
          },
          {
            title: 'Datáfono Plus',
            slug: 'datafonos/plus',
            icon: <StaticImage src="./images/plus.png" alt="Bold menu" />,
          },
          {
            title: 'Datáfono Neo',
            slug: 'datafonos/neo',
            icon: <StaticImage src="./images/neo.png" alt="Bold menu" />,
          },
        ],
      },
      {
        title: 'Pagos en línea',
        pages: [
          {
            upText: 'Link de pago',
            title: 'Por WhatsApp y Redes Sociales',
            slug: 'pagos-en-linea/link-de-pago',
            icon: <StaticImage src="./images/plink.png" alt="Bold menu" />,
          },
          {
            upText: 'Botón de pagos',
            title: 'En tu Página Web',
            slug: 'pagos-en-linea/pasarela-de-pagos',
            icon: <StaticImage src="./images/pbutton.png" alt="Bold menu" />,
          },
        ],
      },
    ],
    featuredArticle: [
      {
        image: [
          <div className="show-mobile">
            <StaticImage src="./images/featured/smart-pro-sm.jpg" alt="Bold menu" />
          </div>,
          <div className="show-table-desktop">
            <StaticImage src="./images/featured/smart-pro-xl.jpg" alt="Bold menu" />
          </div>,
        ],
        upText: 'NUEVO LANZAMIENTO',
        title: 'Múltiples formas de vender en un solo datáfono con impresora',
        link: 'https://datafonos.bold.co/smart-pro/',
      },
    ],
  },
  {
    title: 'Maneja tu dinero',
    link: '',
    childPages: [
      {
        title: 'Maneja tu dinero',
        pages: [
          {
            upText: 'Cuenta Bold',
            title: 'Por Bold C.F.',
            slug: 'cuenta-bold',
            icon: <StaticImage src="./images/boldacc.png" alt="Bold menu" />,
            customContent: (
              <div className="vig">
                <div className="logos">
                  <a href="https://www.superfinanciera.gov.co/" target="_blank" rel="noopener noreferrer">
                    <Vigilado alt="Bold" />
                  </a>
                  <a href="https://www.fogafin.gov.co/" target="_blank" rel="noopener noreferrer">
                    <Fogafin alt="Bold" />
                  </a>
                </div>
              </div>
            ),
          },
        ],
      },
    ],
    featuredArticle: [
      {
        image: <StaticImage src="./images/featured/3.jpg" alt="Bold menu" />,
        title: '3 hacks para el buen manejo de las finanzas de tu negocio',
        link: 'https://boldco.kustomer.help/en_us/3-hacks-para-el-buen-manejo-de-las-finanzas-de-tu-negocio-B1YFaKIJ2',
      },
    ],
  },
  {
    title: 'Recursos',
    link: '',
    childPages: [
      {
        title: 'Recursos',
        pages: [
          {
            title: 'Academia',
            slug: 'academia',
            icon: <StaticImage src="./images/academy.png" alt="Bold menu" />,
          },
          {
            title: 'Impulso emprendedor',
            link: 'https://datafonos.bold.co/impulso-emprendedor/?utm_source=web',
            blank: true,
            icon: <StaticImage src="./images/impulse.png" alt="Bold menu" />,
          },
        ],
      },
    ],
    featuredArticle: [
      {
        image: <StaticImage src="./images/featured/4.jpg" alt="Bold menu" />,
        title: '¿Qué son las retenciones de impuestos de ley?',
        link: 'https://ayuda.bold.co/es/que-son-las-retenciones-de-ley-By0XSD4at',
      },
      {
        image: <StaticImage src="./images/featured/2.jpg" alt="Bold menu" />,
        title: '3 hacks para el buen manejo de las finanzas de tu negocio',
        link: 'https://boldco.kustomer.help/en_us/3-hacks-para-el-buen-manejo-de-las-finanzas-de-tu-negocio-B1YFaKIJ2',
      },
    ],
  },
  { title: 'Tarifas', slug: 'tarifas' },
  {
    title: 'Soporte',
    link: '',
    childPages: [
      {
        title: 'Soporte',
        pages: [
          {
            title: 'Contáctanos',
            slug: 'contacto',
            icon: <StaticImage src="./images/contact.png" alt="Bold menu" />,
          },
          {
            title: 'Centro de ayuda',
            link: 'https://ayuda.bold.co',
            blank: true,
            icon: <StaticImage src="./images/help.png" alt="Bold menu" />,
          },
          {
            title: 'Soporte a compradores',
            slug: 'soporte-compradores',
            icon: <StaticImage src="./images/buyers.png" alt="Bold menu" />,
          },
          {
            title: 'Seguridad para negocios Bold',
            slug: 'seguridad-negocios',
            icon: <StaticImage src="./images/shops.png" alt="Bold menu" />,
          },
        ],
      },
    ],
    featuredArticle: [
      {
        image: <StaticImage src="./images/featured/1.jpg" alt="Bold menu" />,
        title: '¿Qué son las retenciones de impuestos de ley?',
        link: 'https://ayuda.bold.co/es/que-son-las-retenciones-de-ley-By0XSD4at',
      },
    ],
  },
];

export default staticMenuItems;
