import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { getSearchParams } from 'utils/search-params-functions';
import MoreInformation from 'components/Footer/MoreInformation';
import { Link } from 'gatsby';
import maskString from 'utils/mask-string';
import COMPANY_INFO from 'constants/company-info';
import BoldIcon from 'assets/bold-icon.inline.svg';
import LinkedinIcon from 'assets/linkedin-icon.inline.svg';
import YoutubeIcon from 'assets/youtube-icon.inline.svg';
import InstagramIcon from 'assets/instagram-icon.inline.svg';
import FacebookIcon from 'assets/facebook-icon.inline.svg';
import Fogafin from './images/svg/Fogafin.inline.svg';
import Vigi from './images/svg/vigilado.inline.svg';
import LogoCF from './images/svg/boldcf.inline.svg';
import Sic from './images/svg/SIC.inline.svg';

import './styles.scss';

const Footer = ({ accountPage, home }) => {
  const goToSupportURL = whatsAppOptions => {
    const whatsAppURL = `/whatsapp?${Object.keys(whatsAppOptions)
      .map(key => `${key}=${encodeURIComponent(whatsAppOptions[key])}`)
      .join('&')}`;

    window.open(whatsAppURL, '_blank', 'noopener noreferrer');
  };
  const handleSalesSupport = e => {
    e.preventDefault();

    const whatsAppOptions = {
      subject: 'sales',
      from: 'float_button',
      phone: 'default',
      text: '¡Hola! me interesa el datáfono Bold, ¿Pueden darme más información?',
    };

    goToSupportURL(whatsAppOptions);
  };

  return (
    <>
      {accountPage ? (
        <>
          <footer className="page-footer account-footer">
            <div className="page-footer__wrapper">
              <div className="item-cf item">
                <div className="page-footer__info-wrapper">
                  <div className="page-footer__logo">
                    <a href="https://boldcf.co/" target="_blank" rel="noopener noreferrer">
                      <LogoCF alt="boldcf" />
                      <span className="sr-only">Ir a la página principal de Boldcf.co</span>
                    </a>
                  </div>
                </div>
                <div className="vigilado">
                  <div className="vig-logos">
                    <a href="https://www.superfinanciera.gov.co/" target="_blank" rel="noopener noreferrer">
                      <Vigi alt="Bold" />
                    </a>
                    <a href="https://www.fogafin.gov.co/" target="_blank" rel="noopener noreferrer">
                      <Fogafin alt="Bold" />{' '}
                    </a>
                  </div>

                  <p>
                    La Cuenta Bold es ofrecida bajo plena responsabilidad de Bold CF Compañía de Financiamiento S.A.,
                    entidad vigilada por la Superintendencia Financiera de Colombia.
                  </p>
                </div>
                <div className="divider"></div>
                <div className="page-footer__copy">
                  <span>© {new Date().getFullYear()} Todos los Derechos Reservados</span>
                  <span>
                    <strong>Bold CF Compañía de Financiamiento S.A. NIT 901.522.349-3</strong>
                  </span>
                  <span>Calle 93a #10-54, Oficina 604, Bogotá D.C., Colombia</span>
                </div>
              </div>
              <div className="item-sas item">
                <div className="page-footer__info-wrapper">
                  <div className="page-footer__logo">
                    <Link to="/">
                      <BoldIcon title="Bold SAS" />
                      <span className="sr-only">Ir a la página principal de Bold.co</span>
                    </Link>
                  </div>
                </div>
                <div className="vigilado">
                  <div className="vig-logos">
                    <Sic alt="Bold Industria y Comercios" />
                  </div>
                  <p>
                    Bold.Co S.A.S. es vigilada por la Superintendencia de Industria y Comercio, y es corresponsal
                    digital de Bold CF Compañía de Financiamiento S.A.
                  </p>
                </div>
                <div className="divider"></div>
                <div className="page-footer__copy">
                  <span>© {new Date().getFullYear()} Todos los Derechos Reservados</span>
                  <span>
                    <strong>Bold.Co S.A.S. Nit. {COMPANY_INFO.NIT}</strong>
                  </span>
                  <span>{COMPANY_INFO.ADDRESS}</span>
                </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        <>
          <footer className={`page-footer ${home ? 'is-home-footer' : ''}`}>
            <div id="home-store"></div>
            <div className="page-footer__wrapper">
              <StaticImage class="footer-sic" src="./images/SIC2.png" alt="Bold Industria y Comercios" />
              <div className="page-footer__info-wrapper">
                <div className="page-footer__logo">
                  <Link to="/">
                    <BoldIcon title="Bold SAS" />
                    <span className="sr-only">Ir a la página principal de Bold.co</span>
                  </Link>
                </div>

                <nav aria-labelledby="footer-social-links" className="page-footer__social">
                  <h2 id="footer-social-links" className="sr-only">
                    Redes Sociales
                  </h2>
                  <ul>
                    <li>
                      <a
                        href={getSearchParams(COMPANY_INFO.SOCIAL.LINKEDIN_URL)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <LinkedinIcon />
                        <span className="sr-only">Bold.co en LinkedIn</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={getSearchParams(COMPANY_INFO.SOCIAL.YOUTUBE_URL)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <YoutubeIcon />
                        <span className="sr-only">Bold.co en YouTube</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={getSearchParams(COMPANY_INFO.SOCIAL.INSTAGRAM_URL)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <InstagramIcon />
                        <span className="sr-only">Bold.co en Instagram</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={getSearchParams(COMPANY_INFO.SOCIAL.FACEBOOK_URL)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FacebookIcon />
                        <span className="sr-only">Bold.co en Facebook</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <nav aria-labelledby="footer-contact-resources" className="page-footer__nav">
                <h2 id="footer-contact-resources" className="page-footer__title">
                  Contáctanos
                </h2>
                <ul className="page-footer__list">
                  <li className="page-footer__list-item">
                    Linea de atención:{' '}
                    <a href={`tel:${COMPANY_INFO.CONTACT.PHONE}`}>
                      {maskString(COMPANY_INFO.CONTACT.PHONE, '(###) ### ####')}
                    </a>
                  </li>
                  <li className="page-footer__list-item">
                    Escríbenos a WhatsApp:{' '}
                    <a href={`tel:+${COMPANY_INFO.CONTACT.WHATSAPP.SALES}`} onClick={handleSalesSupport}>
                      {maskString(COMPANY_INFO.CONTACT.WHATSAPP.SALES, '(+##) ### ### ####')}
                    </a>
                  </li>
                  <li className="page-footer__list-item">
                    Email ventas:{' '}
                    <a href={`mailto:${COMPANY_INFO.CONTACT.MAIL.SALES}`}>{COMPANY_INFO.CONTACT.MAIL.SALES}</a>
                  </li>
                  <li className="page-footer__list-item">
                    Email servicio al cliente:{' '}
                    <a href={`mailto:${COMPANY_INFO.CONTACT.MAIL.SUPPORT}`}>{COMPANY_INFO.CONTACT.MAIL.SUPPORT}</a>
                  </li>
                </ul>
              </nav>
              <nav aria-labelledby="footer-help-resources" className="page-footer__nav">
                <h2 id="footer-help-resources" className="page-footer__title">
                  Ayuda
                </h2>
                <ul className="page-footer__list">
                  <li className="page-footer__list-item">
                    <Link to={getSearchParams('/legal/')} target="_blank" rel="noopener noreferrer">
                      Legal y privacidad
                    </Link>
                  </li>
                  <li className="page-footer__list-item">
                    <a
                      href={getSearchParams('https://ayuda.bold.co/lang/es/')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Centro de ayuda
                    </a>
                  </li>
                  <li className="page-footer__list-item">
                    <Link to={getSearchParams('/soporte-compradores')} target="_blank" rel="noopener noreferrer">
                      Soporte a compradores
                    </Link>
                  </li>
                  <li className="page-footer__list-item">
                    <Link to={getSearchParams('/contacto')} target="_blank" rel="noopener noreferrer">
                      Contáctanos
                    </Link>
                  </li>
                  <li className="page-footer__list-item">
                    <Link to={getSearchParams('/sitemap')} target="_blank" rel="noopener noreferrer">
                      Mapa del sitio
                    </Link>
                  </li>
                  <li className="page-footer__list-item">
                    <a
                      href="https://docs.google.com/forms/d/1cHGkmkV_-ny1p9nP96eTSiHLZ2j2rNAPHNxm-I66sXI/viewform?ts=6273f6da&edit_requested=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Incumplimiento de código de ética
                    </a>
                  </li>
                </ul>
              </nav>
              <nav aria-labelledby="footer-about-resources" className="page-footer__nav">
                <h2 id="footer-about-resources" className="page-footer__title">
                  Sobre Bold
                </h2>
                <ul className="page-footer__list">
                  <li className="page-footer__list-item">
                    <Link to={getSearchParams('/nosotros')} target="_blank" rel="noopener noreferrer">
                      Nosotros
                    </Link>
                  </li>
                  <li className="page-footer__list-item">
                    <a href="https://boldteam.recruitee.com" target="_blank" rel="noopener noreferrer">
                      Trabaja con Nosotros
                    </a>
                  </li>
                  <li className="page-footer__list-item">
                    <Link to={getSearchParams('/referidos')} target="_blank" rel="noopener noreferrer">
                      Referidos
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="page-footer__copy-section">
                <span>© {new Date().getFullYear()} Todos los Derechos Reservados Bold.</span>
                <span>Bold.co S.A.S. NIT {COMPANY_INFO.NIT}</span>
                <span>{COMPANY_INFO.ADDRESS}</span>
              </div>
            </div>
          </footer>
          <MoreInformation />
        </>
      )}
    </>
  );
};

export default Footer;
