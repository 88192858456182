import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { BoldDialog } from '@bold/web-components-react';
import cookieStorage from 'utils/storage';
import { loopParamsSegment } from 'utils/search-params-functions';
import { segmentTrack } from 'vendors/segment';

import './styles.scss';

const COOKIE_NAME = 'first-time-load-smart-pro';

const ImagePopUpWithCookies = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let timer;

    if (!cookieStorage.getCookie(COOKIE_NAME)) {
      segmentTrack('Pop-Up Viewed', {
        type: 'bold.co',
        page_section: 'pop-up_smart-pro',
        utm: loopParamsSegment(),
        page_name: getPathName(),
      });

      timer = setTimeout(() => {
        setShowModal(true);
      }, 2000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const getPathName = () => {
    return typeof window !== 'undefined' ? window.location.pathname : '';
  };

  const onDialogDismiss = () => {
    cookieStorage.setCookie(COOKIE_NAME, true, 5, true);
    setShowModal(false);
  };

  const handleImageClick = () => {
    segmentTrack('Purchase Clicked', {
      type: 'bold.co',
      page_section: 'pop-up_smart-pro',
      redirect_to: 'tienda_bold_smart_pro',
      utm: loopParamsSegment(),
      page_name: getPathName(),
    });
    onDialogDismiss();
  };

  return (
    <BoldDialog show={showModal} className="image-popup" onDialogDismiss={onDialogDismiss}>
      <a
        href="https://tienda.bold.co/products/smart-pro"
        title="Tienda Bold - Smart Pro"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleImageClick}
      >
        <StaticImage className="sm-only" src="./promo-sm.jpg" alt="Tienda Bold" />
        <StaticImage className="md-only" src="./promo-xl.jpg" alt="Tienda Bold" />
      </a>
    </BoldDialog>
  );
};

export default ImagePopUpWithCookies;
