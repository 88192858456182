import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

const Seo = ({ customTitle, customDescription, customImage, children, customAuthor, location }) => {
  const {
    site: {
      siteMetadata: { url, title, image, description, author },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          author
          url
          title
          image
          description
        }
      }
    }
  `);

  const getSeoImage = () => {
    if (typeof customImage === 'object') {
      return getSrc(customImage);
    }
    if (typeof customImage === 'string') {
      return `${url}/${customImage}`;
    }
    return `${url}/${image}`;
  };

  const seoTitle = customTitle ? `${customTitle} | ${title}` : title;
  const seoDescription = customDescription || description;
  const seoUrl = location ? `${url}${location.pathname}` : url;
  const seoImage = getSeoImage();
  const seoAuthor = customAuthor || author;

  return (
    <>
      {/* Default / HTML */}
      <title>{seoTitle}</title>

      {/* Primary Meta Tags */}
      <meta name="author" content={seoAuthor} />
      <meta name="description" content={seoDescription} />
      <meta name="theme-color" content="#121E6C" />

      {/* Open Graph / Facebook  */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seoUrl} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={seoUrl} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:image" content={seoImage} />

      {/* Primary Link Tags */}
      <link rel="apple-touch-icon" sizes="180x180" href={`${url}/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${url}/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${url}/favicon-16x16.png`} />
      <link rel="manifest" href={`${url}/site.webmanifest`} />
      <link rel="canonical" href={seoUrl} />

      {children}
    </>
  );
};

export default Seo;
