import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import CookiesPopUp from 'components/CookiesPopUp';
import Seo from 'components/Seo';
import SiteNavigation from 'components/SiteNavigation';
import UserAlert from 'components/UserAlert';
import Footer from 'components/Footer';
import ImagePopUpWithCookies from 'components/website/ImagePopUpWithCookies';

const FabButton = loadable(() => import('components/FabButton'));

const MainLayout = ({ location, children, globalBoost, handleGlobalBoost }) => {
  const [userInteracted, setUserInteracted] = useState(false);
  const acounts = ['/cuenta-bold', '/cuenta-bold/lista-de-espera'];
  const homes = ['/home', '/', '/promo', '/v1', '/v2'];
  const homesNew = ['/home', '/promo', '/v1', '/v2'];
  const hidePopUpHomes = ['/', '/v1'];
  const hidePopUp = hidePopUpHomes.some(hidePopUpHome => location.pathname === hidePopUpHome);
  const isHome = homes.some(home => location.pathname === home);
  const isHomeNew = homesNew.some(home => location.pathname === home);
  const isAccount = acounts.some(account => location.pathname === account);

  useEffect(() => {
    const handleInteraction = () => {
      if (!location.search.includes('CI=true')) {
        setUserInteracted(true);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('click', handleInteraction);
      window.addEventListener('scroll', handleInteraction);
    }

    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('scroll', handleInteraction);
    };
  }, [location.search]);

  return (
    <>
      <header className={isHome ? 'is-home' : ''}>
        {isHome && <div id="site-alerts" className={`${isHome ? 'hide-desktop' : ''}`}></div>}

        <SiteNavigation newHome={isHomeNew} home={isHome} pageName={location.pathname} />
      </header>
      <main>{React.cloneElement(children, { globalBoost })}</main>
      <FabButton {...{ globalBoost, handleGlobalBoost, location }} />
      {userInteracted && (
        <>
          <CookiesPopUp />
          {!hidePopUp && <ImagePopUpWithCookies />}
        </>
      )}
      <Footer newHome={isHomeNew} accountPage={isAccount} home={isHome} />
      <UserAlert location={location} />
    </>
  );
};

export default MainLayout;

export const Head = () => <Seo />;
