import React, { useRef, useState } from 'react';
import useCategoryList from 'hooks/useCategoryList';
import { Link } from 'gatsby';

const MoreInformation = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const container = useRef();
  const categoryList = useCategoryList();

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    setTimeout(() => {
      container.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, 300);
  };

  return (
    <div className="more-info">
      <div className="page-footer__wrapper">
        <ul className={`more-info__button ${isExpanded ? 'expanded' : ''}`}>
          <div role="button" onClick={handleToggle} onKeyDown={handleToggle} tabIndex={0}>
            <li>Más información</li>
          </div>
        </ul>
        <div
          className="more-info__links"
          ref={container}
          style={isExpanded ? { height: container.current.scrollHeight } : { height: '0px' }}
        >
          <ul>
            <li>
              Datáfonos
              <ul>
                <li>
                  <Link to="/datafonos">Ver datáfonos</Link>
                </li>
                <li>
                  <Link to="/datafonos/neo">Datáfono Neo</Link>
                </li>
                <li>
                  <Link to="/datafonos/plus">Datáfono Plus</Link>
                </li>
                <li>
                  <Link to="/datafonos/smart">Datáfono Smart</Link>
                </li>
              </ul>
            </li>
            <li>
              Productos
              <ul>
                <li>
                  <Link to="/pagos-en-linea/link-de-pago">Link de pago</Link>
                </li>
                <li>
                  <Link to="/pagos-en-linea/pasarela-de-pagos">Pasarela de pagos</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/academia">Academia</Link>
              <ul>
                {categoryList.map(category => (
                  <li key={category.id}>
                    <Link to={`/academia/${category.slug}`}>{category.name}</Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link to="/tarifas">Tarifas</Link>
            </li>
            <li>
              Visítanos
              <ul>
                <li>
                  <a
                    href="https://g.page/Altavistacentrocomercial?share&_gl=1*1rm2pcw*_gcl_au*NTQ5MTY5MDc0LjE2ODY2Njc5NjY."
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CC. Alta Vista en Usme
                  </a>
                </li>
                <li>
                  <a
                    href="https://goo.gl/maps/6oBLAJe2k5CP61sb7?_gl=1*t2tkhu*_gcl_au*NTQ5MTY5MDc0LjE2ODY2Njc5NjY."
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CC. Gran Plaza Ensueño
                  </a>
                </li>
                <li>
                  <a
                    href="https://g.page/CentroMayorCC?share&_gl=1*t2tkhu*_gcl_au*NTQ5MTY5MDc0LjE2ODY2Njc5NjY."
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CC. Centro Mayor
                  </a>
                </li>
                <li>
                  <a
                    href="https://g.page/salitreplazacc?share&_gl=1*t2tkhu*_gcl_au*NTQ5MTY5MDc0LjE2ODY2Njc5NjY."
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CC. Salitre Plaza
                  </a>
                </li>
                <li>
                  <a
                    href="https://goo.gl/maps/BVuk4ZySox66HRx98?_gl=1*z4cw8e*_gcl_au*NTQ5MTY5MDc0LjE2ODY2Njc5NjY."
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CC Mercurio - Jumbo Soacha
                  </a>
                </li>
                <li>
                  <a
                    href="https://goo.gl/maps/DmGaKCacpTs5GBCj7?_gl=1*z4cw8e*_gcl_au*NTQ5MTY5MDc0LjE2ODY2Njc5NjY."
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Metro Fontibón
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MoreInformation;
